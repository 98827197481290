import React from 'react';import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import InfoList from '../components/InfoList/InfoList';

export default function PsychoplastogensPage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-psychoplastogens')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <InfoList data={
            {
                list: data.page.data.ul,
                headline: data.page.data.ul_headline,
                paragraph: data.page.data.ul_paragraph,
                cta: { text: data.page.data.ul_cta_text.text, url: data.page.data.ul_cta_url },
            }
        }/>
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const query = graphql`
query {
  page: prismicPsychoplatogens {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
      ul_headline {
        html
      }
      ul_paragraph {
        html
      }
      ul_cta_text {
        text
      }
      ul_cta_url {
        target
        slug
        link_type
        url
      }
      ul {
        headline {
          html
        }
        subhead {
          html
        }
        css_class {
          text
        }
      }
    }
  }
}
`
