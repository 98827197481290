import React from 'react';
import Button from '../Button/Button';
import * as styles from './InfoList.module.scss';
import clsx from 'clsx';
import parse from 'html-react-parser';
import Title from '../Title/Title';

function InfoItem({ data }) {
    return (
        <li className={clsx(styles.item, data.css_class.text)}>
            <div className={styles.itemHeadline}>{parse(data.headline.html)}</div>
            <div className={styles.itemSubhead}>{parse(data.subhead.html)}</div>
        </li>
    )
}

export default function InfoList({ data }) {
    return (
        <section className={clsx(styles.infoList, data.css_class)}>
            <div className={clsx('section-wrapper')}>
                <div className={styles.content}>
                    <div className={styles.headline}>
                      <Title>{parse(data.headline.html)}</Title>
                    </div>
                    <div className={styles.paragraph}>{parse(data.paragraph.html)}</div>
                    <ul className={styles.list}>
                        {data.list.map((item,index) => (
                            <InfoItem key={index} data={item}/>
                        ))}
                    </ul>
                    {data.cta.url.link_type == "Document" &&
                        <Button className={styles.cta} url={"/" + data.cta.url.slug}> { data.cta.text } </Button>
                    }
                    {data.cta.url.link_type != "Document" &&
                        <Button className={styles.cta} url={data.cta.url.url}> { data.cta.text } </Button>
                    }                    
                </div>
            </div>
        </section>
    )
}
